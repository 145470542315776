.drawerHead[data-v-50fe7aab] {
  display: flex;
  justify-content: space-between;
}
.content[data-v-50fe7aab] {
  width: 92%;
  overflow-x: auto;
  /* 启用水平滚动条 */
  white-space: nowrap;
  /* 防止内容换行 */
  min-height: 72px;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  position: relative;
}
.content span[data-v-50fe7aab] {
  line-height: 28px;
  font-size: 13px;
}
.content tr[data-v-50fe7aab] {
  padding: 0;
  margin: 0;
}
.content td[data-v-50fe7aab] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  height: 32px;
  padding: 0 6px;
  color: #333;
  font-size: 13px;
}
